import React from 'react'
import { Router } from '@reach/router'
import RAC from '../../../templates/RAC'
import DynamicNotFoundPage from '../../dynamicNotFound'

export default () => (
  <Router>
    <RAC path="/ja/gaming-lodging-leisure/*" />
    <RAC path="/zh-cn/gaming-lodging-leisure/*" />
    <RAC path="/zh-tw/gaming-lodging-leisure/*" />
    <RAC path="/pl/gaming-lodging-leisure/*" />
    <RAC path="/ru/gaming-lodging-leisure/*" />
    <RAC path="/it/gaming-lodging-leisure/*" />
    <RAC path="/de/gaming-lodging-leisure/*" />
    <RAC path="/es/gaming-lodging-leisure/*" />
    <RAC path="/fr/gaming-lodging-leisure/*" />
    <RAC path="/ar/gaming-lodging-leisure/*" />
    <RAC path="/id/gaming-lodging-leisure/*" />
    <RAC path="/pt/gaming-lodging-leisure/*" />
    <RAC path="/ko/gaming-lodging-leisure/*" />
    <DynamicNotFoundPage default />
  </Router>
)
